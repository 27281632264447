const rangeSlider = (min, max, valueMin, valueMax, step, name) => ({
  // This is the modelable property in the form
  // of { from: ..., to: ...}; min and max
  // values are converted to null
  range: {},

  valueMin: null,
  valueMax: null,
  min: null,
  max: null,
  step: 10,
  hover: null,

  async init() {
    this.min = parseInt(min);
    this.max = parseInt(max);
    this.step = parseInt(step);

    this.valueMin = parseInt(valueMin) || parseInt(min);
    this.valueMax = parseInt(valueMax) || parseInt(max);

    await this.setupModelableReactivity();

    this.$watch('valueMin', this.mintrigger.bind(this));
    this.$watch('valueMax', this.maxtrigger.bind(this));
  },

  async setupModelableReactivity() {
    // If this component is used together with x-modelable, we have to wait for
    // the modeled property to be initialized by the modeling data (using
    // $nextTick), before we can set the current or the default values
    // noinspection JSUnresolvedReference
    await this.$nextTick();

    // Here, the range property is already populated with the
    // modeled data, now we can check if was provided or if
    // we have to set it to the initial component state
    this.range = {
      from: this.range.from ?? (this.valueMin !== this.min ? this.valueMin : null),
      to: this.range.to ?? (this.valueMax !== this.max ? this.valueMax : null),
    };

    // And if the range property was changed due to
    // the modelable mechanism, we have to update
    // the valueMin and valueMax accordingly
    this.valueMin = this.range.from ?? this.valueMin;
    this.valueMax = this.range.to ?? this.valueMax;

    // If you need to reset the slider, when using the modelable
    // mechanism, you just can set the modeled value outside to
    // null and the slider will reset itself to min and max
    this.$watch('range', (value) => {
      if (value === null) {
        this.valueMin = this.min;
        this.valueMax = this.max;
      }
    });
  },

  get minthumb() {
    return ((this.valueMin - this.min) / (this.max - this.min)) * 100;
  },

  get maxthumb() {
    return 100 - ((this.valueMax - this.min) / (this.max - this.min)) * 100;
  },

  mintrigger(value, oldValue) {
    if (isNaN(oldValue)) {
      return;
    }

    if (isNaN(value)) {
      value = oldValue;
    }

    if (oldValue === null) {
      return;
    }

    if (value === parseInt(oldValue)) {
      return;
    }

    if (value === null) {
      this.valueMin = this.min;
    } else {
      this.valueMin = Math.min(value, this.valueMax - this.step);
    }

    if (this.valueMin < this.min) {
      this.valueMin = this.min;
    }

    this.range = this.range ?? {};
    this.range.from = this.valueMin === this.min ? null : this.valueMin;

    this.$dispatch('form-update-value', { name: name + '-from', value: value });
  },

  maxtrigger(value, oldValue) {
    if (isNaN(oldValue)) {
      return;
    }

    if (isNaN(value)) {
      value = oldValue;
    }

    if (oldValue === null) {
      return;
    }

    if (value === parseInt(oldValue)) {
      return;
    }

    if (value === null) {
      this.valueMax = this.max;
    } else {
      this.valueMax = Math.max(value, parseInt(this.valueMin) + this.step);
    }

    if (this.valueMax > this.max) {
      this.valueMax = this.max;
      return;
    }

    this.range = this.range ?? {};
    this.range.to = this.valueMax === this.max ? null : this.valueMax;

    this.$dispatch('form-update-value', { name: name + '-to', value });
  },

  get deactivated() {
    return (
      min === max ||
      (this.valueMin === this.min &&
        this.valueMax === this.max &&
        this.valueMin === this.valueMax - this.step)
    );
  },
});

export default rangeSlider;
