import { BREAKPOINT_SM } from '../../abstracts/responsive';
import { getCookie, setCookie } from '../../abstracts/cookie';

const stickyBanner = () => ({
  showBanner: false,
  init() {
    if (window.innerWidth > BREAKPOINT_SM || parseInt(getCookie('vombfa')) === 1) {
      return; // show only on mobile && do not show if cookie is set
    }

    this.$dispatch('loaded');

    document.addEventListener('scroll', this.toggleBanner.bind(this));
  },

  close() {
    this.$root.remove();
    document.removeEventListener('scroll', this.toggleBanner);
    setCookie('vombfa', 1, 7);
  },

  toggleBanner() {
    this.showBanner = window.scrollY > 0;
  },
});

export default stickyBanner;
