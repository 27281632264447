import scrollLock from '../../abstracts/scrollLock';
import tagmanager from '../../abstracts/tagmanager';

const modal = (autoclose = 0, scrollLockActive = true) => ({
  id: 'modal',
  autocloseTime: autoclose,
  scrollLockActivated: scrollLockActive,
  progressActive: false,
  closeTimeout: null,
  open: false,
  modalContentError: false,
  isSearchModal: false,

  init() {
    window.addEventListener('popstate', () => {
      this.close();
    });
  },

  openModal(id) {
    if (id === this.id) {
      this.toggle();
    }
  },

  closeModal(id) {
    id === this.id && this.close();
  },

  async toggle() {
    if (!this.open && this.scrollLockActivated) {
      history.pushState(this.open, '', '');

      setTimeout(() => {
        this.$dispatch('modal-opened', this.id);
      }, 300);
    }
    this.open = !this.open;
    this.progressActive = false;
    if (this.scrollLockActivated) {
      scrollLock.toggle(this.open);
    }

    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
    }

    this.track();

    if (this.open && this.autocloseTime) {
      await this.$nextTick();
      this.progressActive = true;

      this.closeTimeout = setTimeout(() => {
        this.close();
      }, this.autocloseTime);
    }
  },

  close(goBack) {
    if (!this.open) {
      return;
    }

    if (goBack === true && this.scrollLockActivated) {
      window.history.back();
    }
    this.$dispatch('after-modal-close');
    this.open = false;
    this.$store.modal.close();
    this.track();
    this.progressActive = false;
    if (this.scrollLockActivated) {
      scrollLock.toggle(this.open);
    }

    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
    }
  },

  track() {
    let trackingEvent = this.$root.dataset.trackingEvent;
    let trackingCategory = this.$root.dataset.trackingCategory;
    let trackingData = this.$root.dataset.trackingData;

    if (!trackingEvent) {
      return;
    }

    trackingCategory = trackingCategory + (this.open ? '_open' : '_close');

    if (trackingCategory) {
      tagmanager.event(trackingEvent, { Selection: trackingCategory });
    } else if (trackingData) {
      tagmanager.event(trackingEvent, JSON.parse(trackingData));
    } else {
      // send just event without any data
      tagmanager.event(trackingEvent, {});
    }
  },
});

export default modal;
