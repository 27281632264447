export default class PhotoSwipePreviewSlider {
  constructor(lightbox) {
    this.lightbox = lightbox;

    lightbox.on('afterInit', () => {
      if (lightbox.pswp.options.dataSource.items.length <= 1) {
        document.getElementsByClassName('pswp')[0].classList.add('no-preview');
        return;
      }

      this.initPlugin(lightbox.pswp);
    });
  }

  initPlugin(pswp) {
    pswp.ui.registerElement({
      name: 'custom-preview-slider',
      order: 9,
      isButton: false,
      appendTo: 'root',
      onInit: (el, pswp) => {
        const items = pswp.options.dataSource.items;

        el.innerHTML = `
          <div class="wrapper">
              <div class="images-wrapper">
                  ${items
                    .map(
                      (item, index) => `
                    <div class="image-wrapper js-gallery-image-container" onclick="pswp.goTo(${index})">
                        <img src="${item.dataset.preview}" class="image" data-index="${index}" />
                        <div class="image-overlay"></div>
                    </div>
                  `,
                    )
                    .join('')}
              </div>
          </div>
        `;

        this.markActiveImage(pswp, el);

        pswp.on('change', () => {
          this.markActiveImage(pswp, el);
        });
      },
    });
  }

  markActiveImage(pswp, el) {
    const images = el.querySelectorAll('.js-gallery-image-container');

    images.forEach((img) => {
      img.classList.remove('active-image');
    });

    const activeImage = el.querySelector(`img[data-index="${pswp.currSlide.index}"]`);
    if (activeImage) {
      activeImage.parentElement.classList.add('active-image');
      activeImage.scrollIntoView({
        behavior: 'instant',
        block: 'nearest',
        inline: 'center',
      });
    }
  }
}
